import './App.css';
import * as React from 'react';
import { Admin, Resource, Login, useLocale } from 'react-admin';
import { EventList, EventEdit, EventCreate } from './Model/event';
import { WebinarList, WebinarEdit, WebinarCreate } from './Model/webinar';
import { TermList, TermEdit, TermCreate } from './Model/term';
import { FilmList, FilmEdit, FilmCreate } from './Model/film';
import { FilmGenreList, FilmGenreEdit, FilmGenreCreate } from './Model/filmGenre';
import { StoryList, StoryEdit, StoryCreate } from './Model/story';
import { ItemList, ItemEdit, ItemCreate } from './Model/item';
import { CartList, CartEdit, CartCreate } from './Model/cart';
import { CatalogList, CatalogEdit, CatalogCreate } from './Model/catalog';
import { ProgramList, ProgramEdit, ProgramCreate } from './Model/program';
import { CourseList, CourseEdit, CourseCreate } from './Model/course';
import { CourseExampleCreate } from './Model/courseExample';
import { CourseThemeList, CourseThemeEdit, CourseThemeCreate } from './Model/courseTheme';
import { CourseThemeModuleList, CourseThemeModuleEdit, CourseThemeModuleCreate } from './Model/courseThemeModule';
import { CourseUserManagerList, CourseUserManagerEdit, CourseUserManagerCreate } from './Model/courseUserManager';
import { DocList, DocEdit, DocCreate } from './Model/doc';
import { CourseGuidancesList, CourseGuidancesEdit, CourseGuidancesCreate } from './Model/courseGuidances';
import { CourseCompleteList, CourseCompleteEdit, CourseCompleteCreate } from './Model/courseComplete';
import { TeacherList, TeacherEdit, TeacherCreate } from './Model/teacher';
import { PartnerProjectList, PartnerProjectEdit, PartnerProjectCreate } from './Model/partnerProject';
import { NewsList, NewsEdit, NewsCreate } from './Model/news';
import { PageList, PageEdit, PageCreate } from './Model/page';
import { FaqList, FaqEdit, FaqCreate } from './Model/faq';
import { UserList, UserShow } from './Model/user';
import { UserStudyHistoryList } from './Model/userStudyHistoryList';
import { UserGradeEdit, UserGradeList, UserGradeCreate } from './Model/userGrade';
import { UserCertificateList, UserCertificateShow } from './Model/userCertificate';
import { UserMembershipList, UserMembershipEdit, UserMembershipCreate } from './Model/userMembership';
import { CatalogUserList, CatalogUserEdit, CatalogUserCreate } from './Model/catalogUser';
import { TestList, TestEdit, TestCreate } from './Model/test';
import { TrainingAssistantList, TrainingAssistantEdit, TrainingAssistantCreate } from './Model/trainingAssistant';
import { SystemSettingList, SystemSettingEdit, SystemSettingCreate } from './Model/systemSetting';
import { AccessApiKeyList, AccessApiKeyEdit, AccessApiKeyCreate } from './Model/accessApiKey';
import { ManagerList, ManagerEdit, ManagerCreate } from './Model/manager';
import { ManagerRoleList, ManagerRoleEdit, ManagerRoleCreate } from './Model/managerRole';
import { EmailTemplateList, EmailTemplateEdit, EmailTemplateCreate } from './Model/emailTemplate';
import { SystemEventTypeList, SystemEventTypeEdit, SystemEventTypeCreate } from './Model/systemEventType';
import { CertificateList, CertificateEdit, CertificateCreate } from './Model/certificate';
import { GradeList, GradeEdit, GradeCreate } from './Model/grade';
import { ReportCertificateList } from './Model/reportCertificate';
import { ReportCompleteList } from './Model/reportComplete';
import { ReportUserAchiList } from './Model/reportUserAchi';
import { ReportCatalogUserList } from './Model/reportCatalogUser';
import { ReportManagerTaskFileList } from './Model/reportManagerTaskFile';
import { StatisticsList } from './Model/statistics'
import { ManagerLogList, ManagerLogShow } from './Model/managerLog';
import { LangList } from './Model/lang';
import { UserByEmailList } from './Model/userByEmail';
import { DiscountList, DiscountEdit, DiscountCreate } from './Model/discount'
import { SystemNotificationsList, SystemNotificationsEdit, SystemNotificationsCreate } from './Model/systemNotifications'
import Dashboard from './lib/Dashboard';
import authProvider from './lib/authProvider';
import dataProvider from './lib/dataProvider';
import CustomLayout from './lib/CustomLayout';
import customRoutes from './lib/customRoutes';
import { geti18nProvider } from './lib/translate'

const i18nProvider = geti18nProvider()
const template = process.env.REACT_APP_TEMPLATE || 'academy'
let theme
async function importModule(path) {
  try {
    theme = await import(`${path}`);
  } catch (error) {
    console.error('import failed');
  }
}
switch (template) {
  case 'evorich':
    importModule('./lib/themeEvorich');
    break;
  case 'wodcom':
    importModule('./lib/themeWodcom');
    break;
  default:
    importModule('./lib/themeAcademy');
    break;
}

const App = () => (
  <Admin title="Admin Panel"
    loginPage={() => <Login className='login-page_custom' 
    style={{ backgroundImage: 'unset' }} />} 
    dashboard={Dashboard} layout={CustomLayout} 
    dataProvider={dataProvider} 
    i18nProvider={i18nProvider} 
    authProvider={authProvider} 
    customRoutes={customRoutes} 
    theme={theme}
  >
    <Resource
      name="events"
      list={EventList}
      edit={EventEdit}
      create={EventCreate}
    />
    <Resource
      name="webinars"
      list={WebinarList}
      edit={WebinarEdit}
      create={WebinarCreate}
    />
    <Resource
      name="discount"
      list={DiscountList}
      edit={DiscountEdit}
      create={DiscountCreate}
    />
    <Resource
      name="system_notifications"
      list={SystemNotificationsList}
      edit={SystemNotificationsEdit}
      create={SystemNotificationsCreate}
    />
    <Resource
      name="terms"
      list={TermList}
      edit={TermEdit}
      create={TermCreate}
    />
    <Resource
      name="films"
      list={FilmList}
      edit={FilmEdit}
      create={FilmCreate}
    />
    <Resource
      name="films_genre"
      list={FilmGenreList}
      edit={FilmGenreEdit}
      create={FilmGenreCreate}
    />
    <Resource
      name="stories"
      list={StoryList}
      edit={StoryEdit}
      create={StoryCreate}
    />
    <Resource
      name="items"
      list={ItemList}
      edit={ItemEdit}
      create={ItemCreate}
    />
    <Resource
      name="carts"
      edit={CartEdit}
      list={CartList}
      create={CartCreate}
    />
    <Resource
      name="catalog"
      edit={CatalogEdit}
      list={CatalogList}
      create={CatalogCreate}
    />
    <Resource
      name="program"
      edit={ProgramEdit}
      list={ProgramList}
      create={ProgramCreate}
    />
    <Resource
      name="course"
      edit={CourseEdit}
      list={CourseList}
      create={CourseCreate}
    />
    <Resource
      name="course_theme"
      edit={CourseThemeEdit}
      list={CourseThemeList}
      create={CourseThemeCreate}
    />
    <Resource
      name="course_theme_module"
      edit={CourseThemeModuleEdit}
      list={CourseThemeModuleList}
      create={CourseThemeModuleCreate}
    />
    <Resource
      name="course_guidance"
      edit={CourseGuidancesEdit}
      list={CourseGuidancesList}
      create={CourseGuidancesCreate}
    />
    <Resource
      name="course_complete"
      edit={CourseCompleteEdit}
      list={CourseCompleteList}
      create={CourseCompleteCreate}
    />
    <Resource
      name="course_example"
      list={CourseExampleCreate}
    />
    <Resource
      name="teacher"
      edit={TeacherEdit}
      list={TeacherList}
      create={TeacherCreate}
    />
    <Resource
      name="partner_project"
      edit={PartnerProjectEdit}
      list={PartnerProjectList}
      create={PartnerProjectCreate}
    />
    <Resource
      name="news"
      edit={NewsEdit}
      list={NewsList}
      create={NewsCreate}
    />
    <Resource
      name="page"
      edit={PageEdit}
      list={PageList}
      create={PageCreate}
    />
    <Resource
      name="faq"
      edit={FaqEdit}
      list={FaqList}
      create={FaqCreate}
    />
    <Resource
      name="user"
      list={UserList}
      show={UserShow}
    />
    <Resource
      name="user_grade"
      edit={UserGradeEdit}
      list={UserGradeList}
      create={UserGradeCreate}
    />
    <Resource
      name="user_study_history"
      list={UserStudyHistoryList}
    />
    <Resource
      name="user_certificate"
      show={UserCertificateShow}
      list={UserCertificateList}
    />
    <Resource
      name="user_membership"
      edit={UserMembershipEdit}
      list={UserMembershipList}
      create={UserMembershipCreate}
    />
    {/* <Resource
      name="user_subscribe"
      list={UserSubscribeList}
    /> */}
    <Resource
      name="catalog_user"
      edit={CatalogUserEdit}
      list={CatalogUserList}
      create={CatalogUserCreate}
    />
    <Resource
      name="training_assistant"
      edit={TrainingAssistantEdit}
      list={TrainingAssistantList}
      create={TrainingAssistantCreate}
    />
    <Resource
      name="system_setting"
      edit={SystemSettingEdit}
      list={SystemSettingList}
      create={SystemSettingCreate}
    />
    <Resource
      name="access_api_key"
      edit={AccessApiKeyEdit}
      list={AccessApiKeyList}
      create={AccessApiKeyCreate}
    />
    <Resource
      name="manager"
      edit={ManagerEdit}
      list={ManagerList}
      create={ManagerCreate}
    />
    <Resource
      name="manager_role"
      edit={ManagerRoleEdit}
      list={ManagerRoleList}
      create={ManagerRoleCreate}
    />
    <Resource
      name="manager_log"
      show={ManagerLogShow}
      list={ManagerLogList}
    />
    <Resource
      name="email_template"
      edit={EmailTemplateEdit}
      list={EmailTemplateList}
      create={EmailTemplateCreate}
    />
    <Resource
      name="system_event_type"
      edit={SystemEventTypeEdit}
      list={SystemEventTypeList}
      create={SystemEventTypeCreate}
    />
    <Resource
      name="certificate"
      edit={CertificateEdit}
      list={CertificateList}
      create={CertificateCreate}
    />
    <Resource
      name="course_user_manager"
      edit={CourseUserManagerEdit}
      list={CourseUserManagerList}
      create={CourseUserManagerCreate}
    />
    <Resource
      name="grade"
      edit={GradeEdit}
      list={GradeList}
      create={GradeCreate}
    />
    <Resource
      name="report_certificate"
      list={ReportCertificateList}
    />
    <Resource
      name="report_complete"
      list={ReportCompleteList}
    />
    <Resource
      name="langs"
      list={LangList}
    />
    <Resource
      name="admin_panel/user_by_email"
      list={UserByEmailList}
    />
    <Resource
      name="report_statistic/course"
      list={StatisticsList}
    />
    <Resource
      name="report_user_achi"
      list={ReportUserAchiList}
    />
    <Resource
      name="report_catalog_user"
      list={ReportCatalogUserList}
    />
    <Resource
      name="report_manager_task_file"
      list={ReportManagerTaskFileList}
    />
    <Resource
      name="doc"
      edit={DocEdit}
      list={DocList}
      create={DocCreate}
    />
    <Resource
      name="tests"
      edit={TestEdit}
      list={TestList}
      create={TestCreate}
    />
  </Admin>
);
export default App;
